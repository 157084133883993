export const hasNFC = () => {
  return ('NDEFReader' in window)
}

export const scanNFC = () => {
  return new Promise((resolve, reject) => {
    const reader = new NDEFReader()
    reader.scan().then(() => {
      reader.onerror = () => {
        reject(Error('No se puede leer la etiqueta NFC'))
      }
      reader.onreading = event => {
        if (event.serialNumber) {
          event.tagID = event.serialNumber
        }
        resolve(event)
      }
    }).catch(error => {
      reject(error)
    })
  })
}
