var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('v-radio-group',{attrs:{"mandatory":""},on:{"change":_vm.changeTipoCaso},model:{value:(_vm.tipoCaso),callback:function ($$v) {_vm.tipoCaso=$$v},expression:"tipoCaso"}},[(_vm.idvigilante)?_c('v-radio',{attrs:{"label":_vm.casoOptions.enServicio.descripcion,"value":_vm.casoOptions.enServicio.id}}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"punto_servicio","rules":_vm.formRules.punto_servicio,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.idvigilante)?_c('v-text-field',{staticClass:"input-punto pl-5",attrs:{"disabled":_vm.tipoCaso !== _vm.ENTRADASALIDA.caso.enServicio,"label":"Punto de acceso","type":"text","autocomplete":"off","clearable":"","error-messages":errors},on:{"change":_vm.changePunto},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.hasNFC)?_c('v-icon',{staticClass:"mt-1 ml-1",on:{"click":_vm.clickScanNFC}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.nfc)+" ")]):_vm._e(),_c('v-icon',{staticClass:"mt-1 ml-3",on:{"click":_vm.clickQR}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.qr)+" ")])]},proxy:true}],null,true),model:{value:(_vm.form.punto_servicio),callback:function ($$v) {_vm.$set(_vm.form, "punto_servicio", $$v)},expression:"form.punto_servicio"}}):_vm._e()]}}])}),_c('ValidationProvider',{attrs:{"name":"puesto_servicio_servicio","rules":_vm.formRules.puesto_servicio_servicio},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.idvigilante)?_c('v-select',{staticClass:"pl-5",attrs:{"disabled":_vm.tipoCaso !== _vm.ENTRADASALIDA.caso.enServicio,"label":"Servicio","item-text":"descripcion","items":_vm.formattedServicios,"clearable":"","error-messages":errors,"return-object":""},on:{"change":_vm.changeServicio},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" - "+_vm._s(item.subtitle)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.subtitle)}})],1)]}}],null,true),model:{value:(_vm.form.puesto_servicio_servicio),callback:function ($$v) {_vm.$set(_vm.form, "puesto_servicio_servicio", $$v)},expression:"form.puesto_servicio_servicio"}}):_vm._e()]}}])}),_c('v-radio',{directives:[{name:"show",rawName:"v-show",value:(_vm.idvigilante),expression:"idvigilante"}],staticClass:"mt-10",attrs:{"label":_vm.casoOptions.otro.descripcion,"value":_vm.casoOptions.otro.id}}),_c('ValidationProvider',{class:{ 'pl-5': !!_vm.idvigilante },attrs:{"name":"punto_otro_caso","rules":_vm.formRules.punto_otro_caso,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-punto",attrs:{"disabled":_vm.tipoCaso !== _vm.ENTRADASALIDA.caso.otro,"label":"Punto de acceso","type":"text","autocomplete":"off","clearable":"","error-messages":errors},on:{"change":_vm.changePunto},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.hasNFC)?_c('v-icon',{staticClass:"mt-1 ml-1",on:{"click":_vm.clickScanNFC}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.nfc)+" ")]):_vm._e(),_c('v-icon',{staticClass:"mt-1 ml-3",on:{"click":_vm.clickQR}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.qr)+" ")])]},proxy:true}],null,true),model:{value:(_vm.form.punto_otro_caso),callback:function ($$v) {_vm.$set(_vm.form, "punto_otro_caso", $$v)},expression:"form.punto_otro_caso"}})]}}])})],1),_c('b10-textarea',{staticClass:"mt-3",attrs:{"label":"Observaciones"},model:{value:(_vm.form.observaciones),callback:function ($$v) {_vm.$set(_vm.form, "observaciones", $$v)},expression:"form.observaciones"}}),_c('v-text-field',{attrs:{"type":"number","label":"Latitud","disabled":""},model:{value:(_vm.form.latitud),callback:function ($$v) {_vm.$set(_vm.form, "latitud", _vm._n($$v))},expression:"form.latitud"}}),_c('v-text-field',{attrs:{"type":"number","label":"Longitud","disabled":""},model:{value:(_vm.form.longitud),callback:function ($$v) {_vm.$set(_vm.form, "longitud", _vm._n($$v))},expression:"form.longitud"}}),(_vm.form.latitud && _vm.form.longitud)?_c('v-btn',{attrs:{"block":"","color":"secondary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickIrMapa(_vm.form.latitud, _vm.form.longitud)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.mapLocation)+" ")]),_vm._v(" Localización ")],1):_vm._e(),_c('b10-qr-reader',{attrs:{"showing":_vm.showingDialogs.scanQR,"title":"Leer código QR"},on:{"update:showing":function($event){return _vm.$set(_vm.showingDialogs, "scanQR", $event)},"decode":_vm.decodeQR}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }