<template>
  <b10-base>
    <v-radio-group
      v-model="tipoCaso"
      mandatory
      @change="changeTipoCaso"
    >
      <!-- puesto de servicio -->
      <v-radio
        v-if="idvigilante"
        :label="casoOptions.enServicio.descripcion"
        :value="casoOptions.enServicio.id"
      />
      <ValidationProvider
        v-slot="{ errors }"
        name="punto_servicio"
        :rules="formRules.punto_servicio"
        mode="lazy"
      >
        <v-text-field
          v-if="idvigilante"
          v-model="form.punto_servicio"
          :disabled="tipoCaso !== ENTRADASALIDA.caso.enServicio"
          class="input-punto pl-5"
          label="Punto de acceso"
          type="text"
          autocomplete="off"
          clearable
          :error-messages="errors"
          @change="changePunto"
        >
          <template #append-outer>
            <v-icon
              v-if="hasNFC"
              class="mt-1 ml-1"
              @click="clickScanNFC"
            >
              {{ $vuetify.icons.values.nfc }}
            </v-icon>
            <v-icon
              class="mt-1 ml-3"
              @click="clickQR"
            >
              {{ $vuetify.icons.values.qr }}
            </v-icon>
          </template>
        </v-text-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="puesto_servicio_servicio"
        :rules="formRules.puesto_servicio_servicio"
      >
        <v-select
          v-if="idvigilante"
          v-model="form.puesto_servicio_servicio"
          class="pl-5"
          :disabled="tipoCaso !== ENTRADASALIDA.caso.enServicio"
          label="Servicio"
          item-text="descripcion"
          :items="formattedServicios"
          clearable
          :error-messages="errors"
          return-object
          @change="changeServicio"
        >
          <template #selection="{ item }">
            {{ item.title }} - {{ item.subtitle }}
          </template>
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-html="item.title" />
              <v-list-item-subtitle v-html="item.subtitle" />
            </v-list-item-content>
          </template>
        </v-select>
      </ValidationProvider>
      <!-- otro caso -->
      <v-radio
        v-show="idvigilante"
        class="mt-10"
        :label="casoOptions.otro.descripcion"
        :value="casoOptions.otro.id"
      />
      <ValidationProvider
        v-slot="{ errors }"
        name="punto_otro_caso"
        :class="{ 'pl-5': !!idvigilante }"
        :rules="formRules.punto_otro_caso"
        mode="lazy"
      >
        <v-text-field
          v-model="form.punto_otro_caso"
          :disabled="tipoCaso !== ENTRADASALIDA.caso.otro"
          class="input-punto"
          label="Punto de acceso"
          type="text"
          autocomplete="off"
          clearable
          :error-messages="errors"
          @change="changePunto"
        >
          <template #append-outer>
            <v-icon
              v-if="hasNFC"
              class="mt-1 ml-1"
              @click="clickScanNFC"
            >
              {{ $vuetify.icons.values.nfc }}
            </v-icon>
            <v-icon
              class="mt-1 ml-3"
              @click="clickQR"
            >
              {{ $vuetify.icons.values.qr }}
            </v-icon>
          </template>
        </v-text-field>
      </ValidationProvider>
    </v-radio-group>
    <b10-textarea
      v-model="form.observaciones"
      class="mt-3"
      label="Observaciones"
    />
    <v-text-field
      v-model.number="form.latitud"
      type="number"
      label="Latitud"
      disabled
    />
    <v-text-field
      v-model.number="form.longitud"
      type="number"
      label="Longitud"
      disabled
    />
    <v-btn
      v-if="form.latitud && form.longitud"
      block
      color="secondary"
      dark
      @click.stop="clickIrMapa(form.latitud, form.longitud)"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.mapLocation }}
      </v-icon> Localización
    </v-btn>
    <b10-qr-reader
      :showing.sync="showingDialogs.scanQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { getLatLongURL } from '@/utils/maps'
import Data from './RegistrarEntradaSalidaFormData'
import { hasNFC, scanNFC } from '@/utils/nfc'
import { PARAMETRO, ENTRADASALIDA } from '@/utils/consts'
import _ from '@/utils/lodash'
import  { extend } from 'vee-validate'

export default {
  mixins: [formMixin],
  props: {
    idvigilante: {
      type: Number,
      default: 0,
    },
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      puestosServicioServicio: [],
      form: {
        punto_servicio: '',
        punto_otro_caso: '',
        puesto_servicio_servicio: null,
        idpunto: null,
        observaciones: '',
        latitud: null,
        longitud: null,
      },
      formRules: {
        punto_servicio: { required: true, esPuntoDeAccesoDelServicio: true },
        puesto_servicio_servicio: { required: true },
        punto_otro_caso: { required: true, existePuntoOtroCaso: true },
      },
      geolocation: null,
      showingDialogs: {
        scanQR: false,
      },
      hasNFC: hasNFC(),
      casoOptions: {
        enServicio: {
          descripcion: 'En un puesto de servicio',
          id: ENTRADASALIDA.caso.enServicio
        },
        otro: {
          descripcion: 'Otro caso',
          id: ENTRADASALIDA.caso.otro
        },
      },
      tipoCaso: null,
      ENTRADASALIDA,
      puntoRequeridoPorParametro: false,
    }
  },
  computed: {
    formattedServicios () {
      let serviciosFiltrados = []
      if (this.form.idpunto) {
        serviciosFiltrados = _.filter(this.puestosServicioServicio, { idpunto: this.form.idpunto })
      } else {
        serviciosFiltrados = this.puestosServicioServicio
      }
      return _.map(serviciosFiltrados, (item) => {
        item.title = `${item.cliente_nombre}`
        item.subtitle = `${item.descripcion} - ${item.puesto_servicio_descripcion}`
        return item
      })
    },
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.$set(this.form, 'latitud', newValue.lat)
          this.$set(this.form, 'longitud', newValue.lng)
        }
      },
      deep: true,
    },
  },
  async created () {
    this.puntoRequeridoPorParametro = (
      await this.$online.parametro.valorParametro(PARAMETRO.parametros.PRESENCIA_PUNTO_ACCESO_OBLIGATORIO)
    ) === '1'
    if (this.idvigilante) {
      const selectLookups = await Data.selectLookups(this, this.idvigilante)
      const pksPuestoServicioServicio = _.concat(
        _.map(selectLookups.data.selectVigilantePuestoServicio.result.dataset, 'idpuesto_servicio_servicio'),
        _.map(selectLookups.data.selectCuadranteHoy.result.dataset, 'idpuesto_servicio_servicio')
      )
      const [datasetPuestoServicioServicio] = await Data.selectPuestoServicioServicioRows(this, pksPuestoServicioServicio)
      this.puestosServicioServicio = _.sortBy(datasetPuestoServicioServicio, 'cliente_nombre', 'descripcion')
      this.tipoCaso = ENTRADASALIDA.caso.enServicio
    } else {
      this.tipoCaso = ENTRADASALIDA.caso.otro
    }
    this.changeTipoCaso(this.tipoCaso)
    extend('esPuntoDeAccesoDelServicio', {
      message: 'El punto indicado no es el punto de acceso del servicio',
      validate: async (value) => {
        if (value) {
          // volver a buscar el idpunto según el código scanner porque:
          // - se dispara el changePunto que asigna form.idpunto
          // - pero se puede ejecutar esta validación antes de que se haga la asignación de form.idpunto
          const datasetPuntos = await Data.selectPuntoCodigoScanner(this, value)
          if (datasetPuntos.length > 0) {
            return datasetPuntos[0].idpunto === this.form.puesto_servicio_servicio?.idpunto
          } else {
            return false
          }
        } else {
          return true
        }
      }
    })
    extend('existePuntoOtroCaso', {
      message: 'El punto de acceso no existe',
      validate: async (value) => {
        const datasetPuntos = await Data.selectPuntoCodigoScanner(this, value)
        if (datasetPuntos.length > 0) {
          this.$set(this.form, 'idpunto', datasetPuntos[0].idpunto)
          return true
        } else {
          this.$set(this.form, 'idpunto', null)
          return false
        }
      }
    })
    await this.loadForm()
  },
  methods: {
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    async clickScanNFC () {
      this.$loading.showManual('Esperando lectura de etiqueta NFC...', true)
      try {
        const tag = await scanNFC()
        if (tag && tag.tagID) {
          if (this.tipoCaso === ENTRADASALIDA.caso.enServicio) {
            this.$set(this.form, 'punto_servicio', tag.tagID)
          } else {
            this.$set(this.form, 'punto_otro_caso', tag.tagID)
          }
          this.changePunto(tag.tagID)
          this.$alert.showSnackbarSuccess('Etiqueta NFC leída')
        } else {
          this.$alert.showSnackbarError('No se puede leer la etiqueta NFC')
        }
      } finally {
        this.$loading.hide()
      }
    },
    clickQR () {
      this.showingDialogs.scanQR = !this.showingDialogs.scanQR
    },
    async decodeQR (value) {
      if (value) {
        if (this.tipoCaso === ENTRADASALIDA.caso.enServicio) {
          this.$set(this.form, 'punto_servicio', value)
        } else {
          this.$set(this.form, 'punto_otro_caso', value)
        }
        this.changePunto(value)
      }
    },
    async changeServicio (value) {
      if (value) {
        this.$set(this.formRules.punto_servicio, 'required', value.punto_obligatorio)
      } else {
        this.$set(this.formRules.punto_servicio, 'required', true)
      }
    },
    async changePunto (value) {
      if (value) {
        const datasetPuntos = await Data.selectPuntoCodigoScanner(this, value)
        if (datasetPuntos.length > 0) {
          const primerPunto = datasetPuntos[0].idpunto
          this.$set(this.form, 'idpunto', primerPunto)
          const servicio = _.find(this.puestosServicioServicio, { idpunto: primerPunto })
          if (servicio) {
            this.$set(this.form, 'puesto_servicio_servicio', servicio)
          } else {
            this.$set(this.form, 'puesto_servicio_servicio', null)
          }
        } else {
          this.$set(this.form, 'idpunto', null)
        }
      } else {
        this.$set(this.form, 'idpunto', null)
      }
    },
    changeTipoCaso (value) {
      this.$set(this.form, 'idpunto', null)
      this.$set(this.form, 'puesto_servicio_servicio', null)
      this.$set(this.form, 'punto_servicio', null)
      this.$set(this.form, 'punto_otro_caso', null)
      if (value === ENTRADASALIDA.caso.enServicio) {
        this.$set(this.formRules.puesto_servicio_servicio, 'required', !!this.idvigilante)
        // this.formRules.punto_servicio -> required -> según puesto_servicio_servicio.punto_obligatorio
        this.$set(this.formRules.punto_otro_caso, 'required', false)
      }
      else {
        this.$set(this.formRules.puesto_servicio_servicio, 'required', false)
        this.$set(this.formRules.punto_servicio, 'required', false)
        this.$set(this.formRules.punto_otro_caso, 'required', this.puntoRequeridoPorParametro)
      }
    }
  },
}
</script>

<style scoped>
.input-punto >>> input {
  -webkit-text-security: disc;
}
</style>
