import { VIGILANTE } from '@/utils/consts'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { currentDate, endOfDay, addDays, toDate } from '@/utils/date'

export default {
  async selectLookups(Vue, idvigilante) {
    let apiCalls = []
    const apiFilterPuestoServicioServicioVigilante = new APIFilter()
    apiFilterPuestoServicioServicioVigilante
      .addExact('idvigilante', idvigilante)
      .addGT('estado', 0)
      .addIn('idtrelacion', [
        VIGILANTE.idtrelacion.habitual,
        VIGILANTE.idtrelacion.cubre_puesto,
        VIGILANTE.idtrelacion.conocedor,
      ])
    apiCalls.push({ name: 'selectVigilantePuestoServicio', method: 'vigilantePuestoServicio.select', params: { filter: apiFilterPuestoServicioServicioVigilante } })
    const apiFilterCuadranteHoy = new APIFilter()
    const hoy = currentDate()
    apiFilterCuadranteHoy
      .addExact('idvigilante', idvigilante)
      .addGT('estado', 0)
      .addGTE('fecha', toDate(addDays(hoy, -1)))
      .addLTE('fecha', endOfDay(addDays(hoy, 1)))
    apiCalls.push({ name: 'selectCuadranteHoy', method: 'cuadrante.select', params: { filter: apiFilterCuadranteHoy } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectPuntoCodigoScanner (Vue, codigoScanner) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addExact('codigo_scanner', codigoScanner)
    const respPunto = await Vue.$api.call('punto.select', { filter: apiFilter })
    return respPunto.data.result.dataset
  },
  async selectPuestoServicioServicioRows(Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idpuesto_servicio_servicio', pks)
    const resp = await Vue.$api.call('puestoServicioServicio.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
